<template>
  <div>
    <h1 class="text-h4">Профиль пользователя</h1>
    <v-divider class="my-2"></v-divider>

    <v-card>
      <v-card-text class="d-flex flex-column align-start text-body-2">
        <div class="mb-2">
          <div class="text-caption grey--text text--darken-2">Email</div>
          <div class="d-flex align-center">
            <v-icon small>mdi-email</v-icon>
            <span class="ml-1">{{ loginProfile.email }}</span>
          </div>
        </div>
        <div class="mb-2">
          <div class="text-caption grey--text text--darken-2">Роли</div>
          <div class="d-flex align-center font-weight-bold primary--text">
            <v-icon small>mdi-security</v-icon>
            <v-chip class="ml-1">{{ loginProfile.role }}</v-chip>
          </div>
        </div>
      </v-card-text>
    </v-card>

    <div class="my-2">
      <v-tabs v-model="tabs" background-color="transparent">
        <v-tab key="one" :to="{name:'profile.info'}">Аккаунт</v-tab>
        <v-tab key="two" :to="{name:'profile.settings'}">Настройки</v-tab>
      </v-tabs>
      <router-view></router-view>
    </div>

  </div>
</template>

<script>
import { dashboardPageCommonAttributes } from '@/mixins/DashboardPageCommonAttributes'
import { mapActions, mapGetters } from 'vuex'

export default {
  ...dashboardPageCommonAttributes,
  name: 'ProfilePage',
  data: () => ({
    tabs: null,
    panelMetadata: [0]
  }),
  computed: {
    ...mapGetters('profile', ['profile']),
    loginProfile() {
      if (this.profile) {
        return {
          'email': this.profile.email,
          'role': this.profile.role
        }
      }

      return {
        'email': '',
        'role': ''
      }
    }
  },
  async created() {
    await this.fetchProfile()
  },
  methods: {
    ...mapActions('profile', ['fetchProfile'])
  },
  head: {
    title: function () {
      return {
        inner: 'Профиль'
      }
    }
  }
}
</script>

<style scoped>

</style>
